import React, { useMemo } from 'react'

import { TimeAgo } from '../../components/date'

export default function RecordTimestamps({ record }) {
  return useMemo(() => (
    <>
      <div className="border-t border-gray-300 pt-4">
        Updated <span className="font-bold"><TimeAgo key={record?.updatedAt} date={record?.updatedAt}/></span> by <span className="font-bold">{record?.updater?.displayName}</span>
      </div>
      { ["Changed", "Published"].includes(record?.editingStatus) && (
        <div>
          Published <span className="font-bold"><TimeAgo key={record?.publishedAt} date={record?.publishedAt}/></span> by <span className="font-bold">{record?.publisher?.displayName}</span>
        </div>
      )}
    </>
  ), [record])
}
