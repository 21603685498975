import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React, { useMemo } from 'react'

export default function RecordEditingStatus({ isDirty, mutating }) {
  return useMemo(() => {
    if (mutating) return (
      <div className="flex space-x-2 text-indigo-500">
        <svg className="animate-spin h-5 w-5 mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <div>Saving data...</div>
      </div>
    )
    if (isDirty) return (
      <div className="flex space-x-2 text-orange-500">
        <ExclamationCircleIcon className="h-5 w-5 mt-0.5"/>
        <div className="flex-1">Unsaved changes.<br/>Sync pending.</div>
      </div>
    )
    return (
      <div className="flex space-x-2 text-green-600">
        <CheckCircleIcon className="h-5 w-5 mt-0.5"/>
        <div className="flex-1">Data is in sync.</div>
      </div>
    )
  }, [isDirty, mutating])
}
