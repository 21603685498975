import React, { useContext, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import Button from '../../components/button'
import NotificationContext from '../../components/notifications/context'
import assetFragment from '../../fragments/media'

const CLEAR_CACHE_MUTATION = gql`
  mutation ClearAssetFromCache($id: ID!) {
    clearAssetFromCache(input: { id: $id }) {
      asset {
        ${assetFragment}
      }
    }
  }
`

export default function AssetPurgeButton({ assetId }) {
  const { addNotification } = useContext(NotificationContext)
  const [purge, { loading: purging }] = useMutation(CLEAR_CACHE_MUTATION, {
    onError: (err) => {
      console.log(err)
      addNotification({ title: `Error`, message: `Could not clear the cache.`, type: 'error' })
    },
    onCompleted: (data) => {
      console.log(data)
      addNotification({ title: 'Success', message: `The CDN cache was cleared successfuly.`, type: 'success' })
    }
  })

  return useMemo(() => {
    return (
      <div className="border-t border-gray-300 pt-4">
        <div className='flex items-center justify-between mb-2'>
          <div className='font-bold'>
            CDN Cache
          </div>
        </div>
        <Button
          title="Purge cache"
          variant="indigo"
          className="w-full"
          loading={purging}
          onClick={() => purge({ variables: { id: assetId }})}
          confirm="Do you really want to purge the asset from the CDN cache? Nothing bad can happen if you do. 🫠"
        />
        <div className="text-sm text-gray-500 mt-2">This will remove all variants from the CDN <span className="underline font-bold">not</span> the storage itself.</div>
      </div>
    )
  }, [assetId, purge, purging])
}
