import { CloudArrowDownIcon, CloudArrowUpIcon, CloudIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useController, useFormState } from "react-hook-form"

import Button from '../button'
import FilePreview from '../assets/file-preview'
import Label from './label'
import { Link }  from 'gatsby'
import Loading from '../loading'
import NotificationContext from '../notifications/context'
import { useDropzone } from 'react-dropzone'

export default function File({ label, control, name }) {
  const { addNotification } = useContext(NotificationContext)
  const [loading, setLoading] = useState(false)
  const { errors } = useFormState()
  const { field: { onChange, value }} = useController({ name, control })
  const error = errors[name]
  const hasError = !!error
  const [localValue, setLocalValue] = useState()

  useEffect(() => {
    if (typeof value !== "string") {
      setLocalValue(value)
    }
  }, [value, setLocalValue])

  const onDrop = useCallback(acceptedFiles => {
    const { DirectUpload } = require("@rails/activestorage")
    const uploadFile = (file) => {
      setLoading(true)
      const upload = new DirectUpload(file, process.env.GATSBY_ADMIN_API_UPLOAD_URL, {
        directUploadWillStoreFileWithXHR: (xhr) => {
          xhr.setRequestHeader('x-amz-acl', 'public-read');
        }
      })
      upload.create((error, blob) => {
        setLoading(false)
        if (error) {
          // Handle the error
          console.error('Something went wrong with the upload', error);
          addNotification({ title: 'Upload failed', message: `There was an error uploading the file.`, type: 'error' })
        } else {
          onChange(blob.signed_id)
          setLocalValue(blob)
          addNotification({ title: 'Success', message: `The file was successfuly uploaded. Please wait for it to be saved to the asset.`, type: 'success' })
        }
      })
    }

    Array.from(acceptedFiles).forEach(file => {
      uploadFile(file)
    })
  }, [addNotification, onChange])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  if (localValue?.key && localValue?.filename) {
    return (
      <div className="space-y-2">
        <div className="flex w-full justify-center items-center rounded-md border border-gray-300 p-6 min-h-40 shadow-sm">
          <FilePreview asset={{ [name]: localValue }} width={400} name={name}/>
        </div>
        <div className="flex space-x-3 items-center">
          { localValue?.serviceUrl && (
            <Button
              as={Link}
              to={localValue?.serviceUrl}
              title="Download"
              size="xs"
              icon={<CloudArrowDownIcon className="h-4 w-4"/>}
            />
          )}
          <Button
            title="Remove"
            size="xs"
            icon={<XMarkIcon className="h-4 w-4"/>}
            onClick={() => onChange(null)}
          />
          { localValue?.key && <div className="text-gray-500 text-sm">Key: {localValue?.key}</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-2">
      { label && <Label label={label} htmlFor={name} hasError={hasError}/>}
      <div
        {...getRootProps()}
        className="flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-7 pb-8 h-40"
      >
        { !loading && !isDragActive && (
          <div className="space-y-1 text-center">
            <CloudIcon className="mx-auto h-10 w-10 text-gray-400"/>
            <div className="flex text-sm text-gray-600">
              <div className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                <span>Upload a file</span>
              </div>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        )}
        { !loading && isDragActive && (
          <div className="space-y-1 text-center">
            <CloudArrowUpIcon className="mx-auto h-10 w-10 text-gray-400"/>
            <div className="flex text-sm text-gray-600">
              <p className="pl-1 font-medium">Please release</p>
            </div>
            <p className="text-xs text-gray-500">To upload the file</p>
          </div>
        )}
        { loading && <Loading height="h-full"/> }
        <input
          id={name}
          type="file"
          className="sr-only"
          {...getInputProps()}
        />
      </div>
      { error && <div className="text-sm text-red-500">{error.message}</div> }
    </div>
  )
}
