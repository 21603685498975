export default function pathTo(resource) {
  switch (resource.__typename) {
    case "Asset":
      return `/media/${resource.id}`
    case "Post":
      return `/posts/${resource.id}`
    case "Page":
      return `/pages/${resource.id}`
    case "Recipe":
      return `/recipes/${resource.id}`
    case "Series":
      return `/series/${resource.id}`
    case "Category":
      return `/categories/${resource.id}`
    case "CookbookPost":
      return `/cookbook-post/${resource.id}`
    default:
      return "/"
  }
}
