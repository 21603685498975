import React, { useCallback, useEffect, useState } from 'react'
import { useController, useFormState } from "react-hook-form"

import Label from './label'
import MaxLength from '../max-length'
import { classNames } from '../../helper'

const MAX_LENGTH = 160

export default function Textarea({ label, control, name }) {
  const { errors } = useFormState()
  const { field: { onChange, value }} = useController({ name, control })
  const [localValue, setLocalValue] = useState("")
  const error = errors[name]
  const hasError = !!error

  useEffect(() => {
    setLocalValue(value ?? "")
  }, [value, setLocalValue])

  const onHandleChange = useCallback((e) => {
    const newValue = e.target.value
    setLocalValue(newValue)
    onChange(newValue, { shouldDirty: true })
  }, [onChange, localValue])

  return (
    <div className="space-y-2">
      { label && <Label label={label} htmlFor={name} hasError={hasError}/>}
      <textarea
        id={name}
        className={classNames(
          hasError ? "border-red-500 placeholder:text-red-300" : "border-gray-300",
          "block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        )}
        placeholder={label}
        rows={5}
        maxLength={MAX_LENGTH}
        defaultValue={localValue}
        onChange={onHandleChange}
      />
      <MaxLength string={localValue} maxLength={MAX_LENGTH}/>
      { error && <div className="text-sm text-red-500">{error.message}</div> }
    </div>
  )
}
