// try to safe every 3 seconds, only mutate if status is dirty
import { useEffect } from 'react'
import { useFormContext } from "react-hook-form"

const TIMEOUT_DELAY = 3000

export default function AutoSave({ id, mutate, control }) {
  const { reset, getValues, formState: { isDirty, dirtyFields } } = useFormContext({ control })
  useEffect(() => {
    const interval = setInterval(() => {
      if (isDirty) {
        const values = getValues()

        Object.keys(dirtyFields).forEach(function(key) {
          const value = values[key]
          if (value === null) return dirtyFields[key] = null
          if (Array.isArray(value)) {
            return dirtyFields[key] = value.map(v => v.id)
          }
          if (value instanceof Date) {
            return dirtyFields[key] = value
          }
          if (typeof value === 'object') {
            if (value[0]?.name && value[0]?.size) {
              return dirtyFields[key] = value[0]
            }
            return dirtyFields[key] = value.id
          }
          return dirtyFields[key] = value
        })

        reset(values, { keepDirty: false })

        mutate({
          variables: {
            id, ...dirtyFields
          }
        })
      }
    }, TIMEOUT_DELAY)
    return () => clearInterval(interval)
  }, [dirtyFields, isDirty, getValues, id, mutate, reset])

  return null
}
