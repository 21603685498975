export default `
  id
  titleDe
  titleEn
  descriptionDe
  descriptionEn
  fileDe {
    id
    key
    filename
    serviceUrl
    generalType
  }
  fileEn {
    id
    key
    filename
    serviceUrl
    generalType
  }
  linkedEntries {
    __typename
    titleDe
  }
  updater {
    id
    displayName
  }
  updatedAt
`
