import React, { useMemo } from 'react'

import { Link } from 'gatsby'
import pathTo from '../../routes'

export default function AssetLinkedRecords({ records=[] }) {
  return useMemo(() => {
    if (records.length === 0) return (
      <div className="border-t border-gray-300 pt-4">
        <div className='flex items-center justify-between mb-2'>
          <div className='font-bold'>
            Linked Records
          </div>
        </div>
        <div className="text-sm">No linked records.</div>
      </div>
    )

    return (
      <div className="border-t border-gray-300 pt-4">
        <div className='flex items-center justify-between mb-2'>
          <div className='font-bold'>
            Linked Records
          </div>
        </div>
        <div className="space-y-2">
          {records.map(record => (
            <Link
              key={record.id}
              to={pathTo(record)}
              className="text-sm block group"
            >
              <span className="group-hover:underline">{record.titleDe}</span> - {record.__typename}
            </Link>
          ))}
        </div>
      </div>
    )
  }, [records])
}
