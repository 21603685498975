import React from 'react'
import { SpeakerWaveIcon } from '@heroicons/react/24/outline'

export default function FilePreview({ asset, width=400, height='auto', name="fileDe" }) {
  const type = asset?.[name]?.generalType

  return (
    <div>
      { !asset?.[name] && (
        <div className="p-4">No Preview.</div>
      )}
      { type === 'image' && (
        <img src={`https://assets.zuckerjagdwurst.com/${asset?.[name]?.key}/${width}/${height}/75/true/center/${asset?.[name]?.filename}.jpg?animated=false`} loading="lazy" alt={asset?.titleDe}/>
      )}
      { type === 'audio' && (
        <div className="p-2 flex flex space-x-2 items-center">
          <SpeakerWaveIcon className="w-6 h-6"/>
          <div className="truncate flex-1">{asset[name].filename}</div>
        </div>
      )}
    </div>
  )
}
